import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/uireact/uireact/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Props } from 'docz';
import { UiText, UiHeading } from '@uireact/text';
import { UiSpacing, ColorTokens, ColorCategory } from '@uireact/foundation';
import Playground from '../../../src/Playground';
import { UiCard } from '../src/';
import * as packageJson from '../package.json';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">






    <h1 {...{
      "id": "uicard"
    }}>{`UiCard`}</h1>
    <sup>
  <a href="https://github.com/inavac182/ui-react/blob/main/packages/card/" target="_blank">
    v{packageJson.version}
  </a>
    </sup>
    <blockquote>
      <p parentName="blockquote">{`Let's get that text wrapped`}</p>
    </blockquote>
    <h2 {...{
      "id": "installation"
    }}>{`Installation`}</h2>
    <blockquote>
      <p parentName="blockquote">{`npm i @uireact/foundation @uireact/card`}</p>
    </blockquote>
    <h2 {...{
      "id": "usage"
    }}>{`Usage`}</h2>
    <h3 {...{
      "id": "uicard-1"
    }}>{`UiCard`}</h3>
    <Playground __position={1} __code={'<UiCard>\n  <UiHeading>Card header</UiHeading>\n  <UiText>This is the content</UiText>\n  <UiText size=\"xsmall\">This is the footer</UiText>\n</UiCard>'} __scope={{
      props,
      DefaultLayout,
      Props,
      UiText,
      UiHeading,
      UiSpacing,
      ColorTokens,
      ColorCategory,
      Playground,
      UiCard,
      packageJson,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <UiCard mdxType="UiCard">
    <UiHeading mdxType="UiHeading">Card header</UiHeading>
    <UiText mdxType="UiText">This is the content</UiText>
    <UiText size="xsmall" mdxType="UiText">This is the footer</UiText>
  </UiCard>
    </Playground>
    <h3 {...{
      "id": "card-as-a-link"
    }}>{`Card as a link`}</h3>
    <Playground __position={2} __code={'<UiCard link=\"https://www.uireact.io\" bordered>\n  <UiText>Open UiReact docs</UiText>\n</UiCard>'} __scope={{
      props,
      DefaultLayout,
      Props,
      UiText,
      UiHeading,
      UiSpacing,
      ColorTokens,
      ColorCategory,
      Playground,
      UiCard,
      packageJson,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <UiCard link="https://www.uireact.io" bordered mdxType="UiCard">
    <UiText mdxType="UiText">Open UiReact docs</UiText>
  </UiCard>
    </Playground>
    <h3 {...{
      "id": "uicard-with-click-handler"
    }}>{`UiCard with click handler`}</h3>
    <Playground __position={3} __code={'<UiCard\n  clickHandler={identifier => console.log(`Card ${identifier} clicked`)}\n  identifier=\"My-ID\"\n  squared\n>\n  <UiHeading>Click me!</UiHeading>\n</UiCard>'} __scope={{
      props,
      DefaultLayout,
      Props,
      UiText,
      UiHeading,
      UiSpacing,
      ColorTokens,
      ColorCategory,
      Playground,
      UiCard,
      packageJson,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <UiCard clickHandler={identifier => console.log(`Card ${identifier} clicked`)} identifier="My-ID" squared mdxType="UiCard">
    <UiHeading mdxType="UiHeading">Click me!</UiHeading>
  </UiCard>
    </Playground>
    <h3 {...{
      "id": "uicard-with-image-content"
    }}>{`UiCard with image content`}</h3>
    <Playground __position={4} __code={'<UiCard noPadding>\n  <img\n    src=\"https://upload.wikimedia.org/wikipedia/commons/e/e1/Colima_360.jpg\"\n    style={{ width: \'100%\', borderRadius: \'3px 3px 0px 0px\' }}\n  />\n  <UiSpacing padding={{ all: \'five\' }}>\n    <UiHeading>Card header</UiHeading>\n    <UiText>This is the content</UiText>\n    <UiText size=\"xsmall\">This is the footer</UiText>\n  </UiSpacing>\n</UiCard>'} __scope={{
      props,
      DefaultLayout,
      Props,
      UiText,
      UiHeading,
      UiSpacing,
      ColorTokens,
      ColorCategory,
      Playground,
      UiCard,
      packageJson,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <UiCard noPadding mdxType="UiCard">
    <img src="https://upload.wikimedia.org/wikipedia/commons/e/e1/Colima_360.jpg" style={{
          width: '100%',
          borderRadius: '3px 3px 0px 0px'
        }} />
    <UiSpacing padding={{
          all: 'five'
        }} mdxType="UiSpacing">
      <UiHeading mdxType="UiHeading">Card header</UiHeading>
      <UiText mdxType="UiText">This is the content</UiText>
      <UiText size="xsmall" mdxType="UiText">This is the footer</UiText>
    </UiSpacing>
  </UiCard>
    </Playground>
    <h3 {...{
      "id": "uicard-with-weight"
    }}>{`UiCard with weight`}</h3>
    <Playground __position={5} __code={'<UiSpacing margin={{ block: \'three\' }}>\n  <UiCard weight=\"10\">\n    <UiHeading>Card header</UiHeading>\n    <UiText>This is the content</UiText>\n    <UiText size=\"xsmall\">This is the footer</UiText>\n  </UiCard>\n</UiSpacing>\n<UiSpacing margin={{ block: \'three\' }}>\n  <UiCard weight=\"50\">\n    <UiHeading>Card header</UiHeading>\n    <UiText>This is the content</UiText>\n    <UiText size=\"xsmall\">This is the footer</UiText>\n  </UiCard>\n</UiSpacing>\n<UiSpacing margin={{ block: \'three\' }}>\n  <UiCard weight=\"100\">\n    <UiHeading>Card header</UiHeading>\n    <UiText>This is the content</UiText>\n    <UiText size=\"xsmall\">This is the footer</UiText>\n  </UiCard>\n</UiSpacing>\n<UiSpacing margin={{ block: \'three\' }}>\n  <UiCard weight=\"150\">\n    <UiHeading>Card header</UiHeading>\n    <UiText>This is the content</UiText>\n    <UiText size=\"xsmall\">This is the footer</UiText>\n  </UiCard>\n</UiSpacing>\n<UiSpacing margin={{ block: \'three\' }}>\n  <UiCard weight=\"200\">\n    <UiHeading>Card header</UiHeading>\n    <UiText>This is the content</UiText>\n    <UiText size=\"xsmall\">This is the footer</UiText>\n  </UiCard>\n</UiSpacing>'} __scope={{
      props,
      DefaultLayout,
      Props,
      UiText,
      UiHeading,
      UiSpacing,
      ColorTokens,
      ColorCategory,
      Playground,
      UiCard,
      packageJson,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <UiSpacing margin={{
        block: 'three'
      }} mdxType="UiSpacing">
    <UiCard weight="10" mdxType="UiCard">
      <UiHeading mdxType="UiHeading">Card header</UiHeading>
      <UiText mdxType="UiText">This is the content</UiText>
      <UiText size="xsmall" mdxType="UiText">This is the footer</UiText>
    </UiCard>
  </UiSpacing>
  <UiSpacing margin={{
        block: 'three'
      }} mdxType="UiSpacing">
    <UiCard weight="50" mdxType="UiCard">
      <UiHeading mdxType="UiHeading">Card header</UiHeading>
      <UiText mdxType="UiText">This is the content</UiText>
      <UiText size="xsmall" mdxType="UiText">This is the footer</UiText>
    </UiCard>
  </UiSpacing>
  <UiSpacing margin={{
        block: 'three'
      }} mdxType="UiSpacing">
    <UiCard weight="100" mdxType="UiCard">
      <UiHeading mdxType="UiHeading">Card header</UiHeading>
      <UiText mdxType="UiText">This is the content</UiText>
      <UiText size="xsmall" mdxType="UiText">This is the footer</UiText>
    </UiCard>
  </UiSpacing>
  <UiSpacing margin={{
        block: 'three'
      }} mdxType="UiSpacing">
    <UiCard weight="150" mdxType="UiCard">
      <UiHeading mdxType="UiHeading">Card header</UiHeading>
      <UiText mdxType="UiText">This is the content</UiText>
      <UiText size="xsmall" mdxType="UiText">This is the footer</UiText>
    </UiCard>
  </UiSpacing>
  <UiSpacing margin={{
        block: 'three'
      }} mdxType="UiSpacing">
    <UiCard weight="200" mdxType="UiCard">
      <UiHeading mdxType="UiHeading">Card header</UiHeading>
      <UiText mdxType="UiText">This is the content</UiText>
      <UiText size="xsmall" mdxType="UiText">This is the footer</UiText>
    </UiCard>
  </UiSpacing>
    </Playground>
    <h3 {...{
      "id": "uicard-with-color-category"
    }}>{`UiCard with color category`}</h3>
    <Playground __position={6} __code={'<UiSpacing margin={{ block: \'three\' }}>\n  <UiCard weight=\"10\" category=\"positive\">\n    <UiHeading>Card header</UiHeading>\n    <UiText>This is the content</UiText>\n    <UiText size=\"xsmall\">This is the footer</UiText>\n  </UiCard>\n</UiSpacing>\n<UiSpacing margin={{ block: \'three\' }}>\n  <UiCard weight=\"50\" category=\"negative\">\n    <UiHeading>Card header</UiHeading>\n    <UiText>This is the content</UiText>\n    <UiText size=\"xsmall\">This is the footer</UiText>\n  </UiCard>\n</UiSpacing>\n<UiSpacing margin={{ block: \'three\' }}>\n  <UiCard weight=\"100\" category=\"error\">\n    <UiHeading>Card header</UiHeading>\n    <UiText>This is the content</UiText>\n    <UiText size=\"xsmall\">This is the footer</UiText>\n  </UiCard>\n</UiSpacing>'} __scope={{
      props,
      DefaultLayout,
      Props,
      UiText,
      UiHeading,
      UiSpacing,
      ColorTokens,
      ColorCategory,
      Playground,
      UiCard,
      packageJson,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <UiSpacing margin={{
        block: 'three'
      }} mdxType="UiSpacing">
    <UiCard weight="10" category="positive" mdxType="UiCard">
      <UiHeading mdxType="UiHeading">Card header</UiHeading>
      <UiText mdxType="UiText">This is the content</UiText>
      <UiText size="xsmall" mdxType="UiText">This is the footer</UiText>
    </UiCard>
  </UiSpacing>
  <UiSpacing margin={{
        block: 'three'
      }} mdxType="UiSpacing">
    <UiCard weight="50" category="negative" mdxType="UiCard">
      <UiHeading mdxType="UiHeading">Card header</UiHeading>
      <UiText mdxType="UiText">This is the content</UiText>
      <UiText size="xsmall" mdxType="UiText">This is the footer</UiText>
    </UiCard>
  </UiSpacing>
  <UiSpacing margin={{
        block: 'three'
      }} mdxType="UiSpacing">
    <UiCard weight="100" category="error" mdxType="UiCard">
      <UiHeading mdxType="UiHeading">Card header</UiHeading>
      <UiText mdxType="UiText">This is the content</UiText>
      <UiText size="xsmall" mdxType="UiText">This is the footer</UiText>
    </UiCard>
  </UiSpacing>
    </Playground>
    <h3 {...{
      "id": "uicard-outlined"
    }}>{`UiCard outlined`}</h3>
    <Playground __position={7} __code={'<UiSpacing margin={{ block: \'three\' }}>\n  <UiCard weight=\"10\" category=\"positive\" styling=\"outlined\">\n    <h3>Card header</h3>\n    <p>This is the content</p>\n    <UiText size=\"small\">\n      This is the footer, using UiText styling is different.\n    </UiText>\n  </UiCard>\n</UiSpacing>'} __scope={{
      props,
      DefaultLayout,
      Props,
      UiText,
      UiHeading,
      UiSpacing,
      ColorTokens,
      ColorCategory,
      Playground,
      UiCard,
      packageJson,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <UiSpacing margin={{
        block: 'three'
      }} mdxType="UiSpacing">
    <UiCard weight="10" category="positive" styling="outlined" mdxType="UiCard">
      <h3>Card header</h3>
      <p>This is the content</p>
      <UiText size="small" mdxType="UiText">This is the footer, using UiText styling is different.</UiText>
    </UiCard>
  </UiSpacing>
    </Playground>
    <p>{`The outlined card will give some basic coloration to texts, although if you use any text component
the styling will be overwritten and has to be manually set up on each text component.`}</p>
    <h2 {...{
      "id": "props"
    }}>{`Props`}</h2>
    <Props of={UiCard} mdxType="Props" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      